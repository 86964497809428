.card {
  background-color: #fff;
  border-radius: 7px;
  /* box-shadow: 3.38462px 3.38462px 13.5385px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  margin-bottom: 37px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #d1d1d1;
  width: 100%;
}

.card_pinned {
  background-color: #faf6fe;
  border-radius: 7px;
  /* box-shadow: 3.38462px 3.38462px 13.5385px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  margin-bottom: 37px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #818181;
  width: 100%;
}

.header-img-holder {
  width: 100%;
  height: 200px;
  overflow: hidden;
  cursor: pointer;
}
.header-img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
}
.fee {
  right: 0px;
  position: absolute;
  margin-top: 10%;
  background-color: var(--primary-blue);
  color: white;
  padding: 5px 10px;
  border-radius: 20%;
  box-shadow: rgb(0 0 0 / 15%) 10px 10px 24px;
}
.content {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}
