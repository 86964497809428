.partners-of-pozi {
    max-width: 150px;
}

.sales-slider > div > .slick-slide {
    width: unset !important;
}

.partners-of-pozi-card {
    width: 180px !important;
    height: 160px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    margin-right: 12px;
}

.sales-page-main-container {
    max-width: 1920px;
}

.partners-of-pozi-title {
    margin-left: 360px;
    font-weight: 700;
    font-size: 32px;
    line-height: 62px;
    color: #262626;
    width: 80%;
    margin: 0 auto;
}

.package-title-container {
    text-align: center;
    margin-bottom: 140px;
    margin-top: 68px;
    padding-top: 90px;
}

.package-packages-all-container{
    box-shadow: 0px 4px 24px rgba(176, 176, 176, 0.42);
}

.package-title {
    font-weight: 900;
    font-size: 64px;
    line-height: 82px;
    text-align: center;
    color: #262626;
}

.package-title-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #262626;

}

.packages {
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
    padding-bottom: 90px;
}

.testimonials {
    margin-top: 250px;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
}

.testimonials-inner-box {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    width: 63%;
    position: relative;
}

.sales-first-box {
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    align-items: center;
    height: 575px;
    margin-bottom: 100px;
}

.sales-title-box {
    width: 55%;
}

.sales-first-box-title {
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
    color: #262626;
}

.sales-first-box-description {
    font-weight: 500;
    font-size: 40px;
    line-height: 45px;
    color: #262626;
    width: 65%;
}

.sales-first-box-button {
    width: 150px;
    height: 48.07px;
}

.sales-first-box-image {
    filter: drop-shadow(12px 4px 24px rgba(0, 0, 0, 0.1));
}

.sales-second-box {
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

.sales-second-box-title-title {
    font-weight: 900;
    font-size: 64px;
    line-height: 82px;
    text-align: center;
    color: #262626;
    margin-bottom: 24px;
}

.sales-second-box-title-description {
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: #262626;
}

.sales-third-box {
    width: 80%;
    background: #F7F6FF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 81px;
    margin-top: 63px;
}

.sales-third-box>div {
    width: 47%;
}

.sales-third-box-title {
    font-weight: 900;
    font-size: 40px;
    line-height: 46px;
    color: #262626;
}

.sales-third-box-description {
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #262626;
}

.sales-third-box-description-text {
    margin-bottom: 38px;
}

.text-on-image {
    max-width: 1920px;
    position: relative;
    width: 100%;
}

.text-on-image-desktop {
    width: 100%;
}

.text-on-image-mobile {
    display: none;
}

.text-on-image>div {
    position: absolute;
    top: 213px;
    left: 260px;
    width: 30%;
}

.text-on-image-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;
    color: #FFFFFF;
    margin-bottom: 35px;
}

.text-on-image-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.text-on-image-list>li {
    list-style-type: none;
    margin-left: -40px;
    line-height: 32px;
}

.text-on-image-list {
    margin-bottom: 34px;
}

.all-in-one {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
}

.sales-all-in-one-title-card {
    text-align: center;
}

.sales-all-in-one-details-card {
    display: flex;
}

.sales-all-in-one-right-card {
    display: flex;
    flex-wrap: wrap;
}

.sales-all-in-one-title {
    font-weight: 900;
    font-size: 64px;
    line-height: 82px;
    text-align: center;
    color: #262626;
    margin-bottom: 16px;
    margin-top: 150px;
}

.sales-all-in-one-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #262626;
    margin-bottom: 80px;
}

.sales-all-in-one-left-card {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #262626;
}

.sales-all-in-one-right-card {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #262626;
    justify-content: space-evenly;
}

.sales-all-in-one-bottom-card {
    display: flex;
    justify-content: center;
    z-index: 5;
}

.sales-all-in-one-bottom-card>div {
    width: 75% !important;
    max-width: unset;
    margin-top: 100px;
}

.sales-image-box {
    margin-top: -78px
}

.sales-image-box>img {
    max-width: 100%;
    box-shadow: inset 4px 12px 30px rgba(0, 0, 0, 0.25);
}

.sales-what-we-offer-title {
    font-weight: 900;
    font-size: 64px;
    line-height: 82px;
    text-align: center;
    color: #262626;
    height: 280px;
    margin-top: 150px;
}

.sales-what-we-offer-details {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
}

.sales-what-we-offer-details-images {
    width: 40%;
}

.sales-what-we-offer-details-texts {
    width: 58%;
    flex-basis: max-content;
}

.sales-what-we-offer-details-texts-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 46px;
    color: #262626;
}

.sales-what-we-offer-details-texts-list {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #262626;
    margin-bottom: 52px;
}

.sales-what-we-offer-details-texts-container {
    position: absolute;
    top: -130px;
    width: 42%;
    margin-left: 7%;
}

.sales-what-we-offer-details-texts>img {
    float: right;
}

.sales-what-we-offer-details-images {
    position: relative
}

.sales-what-we-offer-details-images>img {
    position: absolute
}

.sales-what-we-offer-details-images1 {
    margin-left: 24%;
    margin-top: 0;
    z-index: 0;
}

.sales-what-we-offer-details-images2 {
    margin-left: 26%;
    margin-top: 20px;
    width: 489px;
    z-index: 1;
}

.sales-what-we-offer-details-images3 {
    margin-left: 54%;
    top: 250px;
    z-index: 0;
}

.sales-what-we-offer-details-images4 {
    margin-left: 9%;
    top: 300px;
    width: 486px;
    z-index: 2;
}

.sales-what-we-offer-details-images5 {
    margin-left: 33%;
    top: 600px;
    width: 376px;
    z-index: 3;
}

.sales-what-we-offer-details-images6 {
    margin-left: 31%;
    margin-top: 740px;
    z-index: 0;
}

.sales-questions {
    margin-top: 200px;
    box-shadow: inset 3px 6px 24px rgba(73, 60, 87, 0.12);
    background: #FBF7FF;
    padding: 100px 0;
}

.sales-questions-title {
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    color: #262626;
    width: 64%;
    margin: 0 auto;
}

.sales-questions-answers {
    width: 65%;
    margin: 0 auto;
}

.sales-questions-answers>div {
    background: #FBF7FF;
    border: unset;
}

.sales-questions-answers>div>div {
    border-bottom: unset !important;
}

.sales-questions-answers>div>div>div {
    font-weight: 600;
    font-size: 18px;
    line-height: 188.3% !important;
    color: #6755CE !important;
}

.sales-what-we-offer-details-mobile {
    display: none;
}

.testimonial-first-image {
    position: absolute;
    z-index: 0;
    top: -50px;
    margin-left: -100px;
}

.testimonial-second-image {
    position: absolute;
    z-index: 0;
    bottom: 5%;
    right: -100px;
}

.sales-what-we-offer-details-texts>img {
    max-width: 100%;
}

.sales-slider{
    margin-top: 85px;
    margin-bottom: 120px;
}

.sales-second-third-boxes-container{
    padding-top: 250px;
    padding-bottom: 250px;
    background: #E1DBFF;
}

@media(max-width:1600px) {
    .testimonial-second-image {
        bottom: 9%;
    }
}

@media (max-width: 1550px) {
    .package-title-container {
        margin-top: 150px;
    }
}

@media (max-width: 1500px) {
    .sales-what-we-offer-details-texts-container {
        width: 50%;
    }

    .sales-what-we-offer-details-texts>img {
        margin-right: -165px;
    }

    .sales-what-we-offer-details-texts>img {
        margin-right: unset !important;
    }

    .sales-first-box-image {
        max-width: 65%;
    }

    .package-title-container {
        text-align: center;
        margin-bottom: 120px;
        margin-top: 140px;
    }
    .testimonial-second-image {
        bottom: 12%;
    }
}
@media (max-width: 1400px) {
    .text-on-image>div {
        top: 85px;
        left: 150px;
    }
    .testimonial-second-image {
        bottom: 9%;
    }
}

@media (max-width: 1350px) {
    .text-on-image>div {
        width: 40%;
    }

    .sales-what-we-offer-details-texts-container {
        width: 47%;
        margin-left: 10%;
    }

    .sales-what-we-offer-details-texts>img {
        margin-right: -165px;
    }

    .sales-second-box-title-title {
        margin-top: 50px;
    }
    .package-title-container {
        margin-top: 330px;
    }
}

@media (max-width: 1290px) {
    .sales-what-we-offer-details-texts{
        flex-basis: max-content;
    }
}

@media(max-width:1250px) {
    .package-title-container {
        margin-top: 400px;
    }
}

@media (max-width: 1200px) {
    .sales-first-box {
        flex-wrap: wrap;
        margin-right: unset;
        width: 100%;
        margin-left: unset;
        height: unset;
    }

    .sales-title-box {
        margin-top: 0;
        width: 80%;
        margin-right: 0;
        margin-top: 5%;
        text-align: center;
    }

    .sales-first-box-description {
        text-align: center;
        width: auto;
    }

    .sales-third-box {
        flex-wrap: wrap;
    }

    .sales-third-box>div {
        width: 100%;
        margin-bottom: 25px;

    }

    .sales-all-in-one-right-card {
        flex-wrap: wrap;
        justify-content: start;
        margin-left: 70px;
    }

    .sales-what-we-offer-details-images {
        display: none;
    }

    .sales-what-we-offer-details-mobile {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }

    .sales-what-we-offer-details-texts-container {
        width: 80%;
        margin-left: unset;
    }

    .what-we-offer {
        width: 80%;
        margin: 0 auto;
        height: 2550px;
    }

    .sales-what-we-offer-details-texts>img {
        margin-right: 0;
        float: right;
        max-width: 100%;
    }

    .testimonial-card {
        max-width: 100% !important;
    }

    .sales-all-in-one-right-card>div {
        margin-bottom: 20px;
    }

    .text-on-image>div {
        width: 80%;
    }

    .sales-all-in-one-bottom-card>div {
        width: 100% !important;
    }

    .testimonial-second-image {
        right: -90px
    }

    .testimonials {
        margin-bottom: 75px;
    }

    .package-title-container {
        margin-top: 200px;
    }
    .testimonial-second-image {
        bottom: 12%;
    }
}

@media(max-width:1050px) {
    .package-title-container{
        margin-top: 350px;
    }
    .testimonial-second-image {
        bottom: 9%;
    }
}


@media(max-width:920px) {
    .text-on-image>div {
        top: 25px;
        left: 50px;
        width: 95%;
    }
    .package-title-container{
        margin-top: 390px;
    }
    .testimonial-second-image {
        bottom: 10%;
    }
}

@media(max-width:850px) {
    .testimonial-second-image {
        bottom: -4%;
    }
}

@media(max-width:775px) {
    .text-on-image-desktop {
        display: none;
    }

    .text-on-image-mobile {
        display: block;
        width: 100%;
    }

    .text-on-image>div {
        width: 85%;
    }

    .sales-third-box {
        padding: 50px;
    }

    .sales-third-box-title {
        font-size: 32px;
    }

    .sales-first-box-image {
        max-width: 80%;
    }

    .package-title-container{
        margin-top: 450px;
    }
    .text-on-image>div{
        top:100px
    }
    .sales-all-in-one-details-card{
        flex-wrap: wrap;
    }
    .sales-all-in-one-right-card{
        margin-left: unset;
        margin-top: 50px;
    }
    .sales-all-in-one-bottom-card {
        margin-bottom: 75px !important;
    }
}

@media(max-width:650px) {
    .sales-all-in-one-bottom-card>div{
        margin-top: 20px;
    }
    .sales-image-box {
        margin-top: -35px;
    }
    .package-title-container{
        margin-top: 650px;
    }
    .testimonial-first-image {
        margin-left: -90px;
    }
    .sales-all-in-one-bottom-card {
        margin-bottom: 30px !important;
    }
    .testimonial-second-image {
        bottom: -3%;
    }
    .sales-second-third-boxes-container{
        padding-top: 150px;
        padding-bottom: 150px;
    }
    
}

@media(max-width:530px){
    .text-on-image-title {
        font-size: 27px;
        line-height: 38px;
    }

    .text-on-image-description {
        font-size: 16px;
    }

    .text-on-image-list {
        margin-bottom: 10px;
    }

    .text-on-image-list>li {
        line-height: 20px;
    }

    .text-on-image-description {
        line-height: 24px;
    }

    .sales-third-box {
        padding: 24px;
    }

    .sales-third-box {
        width: 95%;
    }

    .sales-first-box-title {
        font-size: 42px;
        line-height: 58px;
    }

    .sales-first-box-image {
        max-width: 100%;
    }

    .sales-second-box-title-title {
        font-size: 42px;
        line-height: 58px;
    }

    .sales-second-box-title-description {
        font-size: 32px;
        line-height: 42px;
    }
    .sales-all-in-one-title {
        font-size: 42px;
        line-height: 58px;
    }
    .sales-what-we-offer-title{
        font-size: 42px;
        line-height: 58px;
    }
    .package-title{
        font-size: 42px;
        line-height: 58px;
    }
    .package-title-container {
        margin-top: 700px;
    }
    .testimonial-first-image {
        margin-left: -85px;
    }
}

@media (max-width: 520px){
    .package-title-container {
        margin-top: 700px;
    }
}
@media(max-width:495px) {
    .text-on-image-title {
        font-size: 32px;
        margin-bottom: 10px;
        line-height: 45px;
    }

    .text-on-image-description {
        font-size: 16px;
    }

    .text-on-image-list {
        margin-bottom: 10px;
    }

    .text-on-image-list>li {
        line-height: 24px;
    }
    .package-title-container {
        margin-top: 850px;
    }
    .testimonial-first-image {
        margin-left: -30px;
    }
    .testimonial-second-image {
        margin-left: -30px;
    }
    .testimonials-inner-box {
        width: 80%;
    }
    .testimonial-second-image {
        right: -40px;
    }
}

@media(max-width:450px) {
    .package-title-container {
        margin-top: 1000px;
    }
    .text-on-image>div{
        top:50px
    }
}

@media(max-width:400px) {
    .package-title-container {
        margin-top: 1350px;
    }
    .text-on-image>div{
        top:25px
    }
}

@media (max-width:380px) {
    .package-title-container {
        margin-top: 1450px;
    }
}