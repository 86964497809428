.top_part {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}

.image_uploaders {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .logo_uploader {
  margin-bottom: 50px;
}
.cover_uploader {
  margin-top: 50px;
} */
.roles {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 460px;
}
.selected_role_item {
  box-shadow: 0 0 19px -7px rgb(0 0 0 / 25%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: all 0.1s;
  padding: 8px;
  min-width: 100px;
  cursor: pointer;
  background-color: var(--primary-blue);
  color: #fff;
}
.role_icon {
  width: 35px;
  height: 35px;
}

.selected_role_item .role_icon {
  filter: invert(1);
}
.role_item {
  transition: all 0.1s;
  box-shadow: 0 0 19px -7px rgb(0 0 0 / 20%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 8px;
  min-width: 100px;
  cursor: pointer;
}
.role_item:hover {
  background-color: var(--primary-blue);
  color: #fff;
}
.role_item:hover .role_icon {
  filter: invert(1);
}
.chooser_container {
  margin-bottom: 28px;
}
.chooser_holder {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 28px;
}
.chooser_item {
  min-width: fit-content;
  border: 1px solid #e1dbff;
  box-sizing: border-box;
  border-radius: 40px;
  order: 2;
  flex-grow: 0;
  padding: 10px;
  text-align: center;
}
.birthday {
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 28px !important;
  width: 100% !important;
}
.subtitle {
  display: inline-block;
  font-size: 14px;
  font-weight: 800;
  margin-right: 25px;
  margin-bottom: 15px;
}

.show_birthday {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  border-radius: 50%;
  background-position: 50%;
  position: relative;
  top: -55px;
  float: right;
  right: 7%;
  margin-right: 2%;
}

.passwords {
  display: flex;
  flex: 20%;
  gap: 20px;
}
.social_holder {
  margin-top: 28px;
  margin-bottom: 28px;
}
.location {
  width: 100%;
}
.button_holder {
  display: flex;
  justify-content: center;
}
.error {
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  color: rgb(245, 34, 45);
}
.social_error {
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  color: rgb(245, 34, 45);
  text-align: center;
  margin-bottom: 10px;
}
.hide_error {
  display: none;
}
.modal {
  width: 95% !important;
  height: fit-content;
}
.select_items_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.details_category {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.details_select{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 500px;
}
.primary_button {
  background: #6755ce;
  border: 1px solid #6755ce;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.cancel_button {
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-right: 30px;
}
.selected_holder {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.selected_item {
  display: flex;
  min-width: fit-content;
  border: 1px solid #e1dbff;
  box-sizing: border-box;
  border-radius: 40px;
  order: 2;
  flex-grow: 0;
  padding: 10px;
  text-align: center;
}
.close_icon {
  margin-left: 10px;
}
.hr {
  width: 100%;
  opacity: 0.21;
  border: 0.5px solid #262626;
  margin-top: 20px;
  height: 0;
}

.email_change{
  width: 34%;
  display: inline-block;
  text-align: right;
}

.email_input_holder > div{
  max-width: 66%;
  display: inline-block;
}

.modal_input{
  margin-bottom: 10px;
}

.education_input{
  margin-top: 20px;
}

.modal_p{
  margin-top: 10px;
}

.email_form > input{
  margin: 10px 0;
}

@media screen and (max-width: 1525px) {
  .select_items_container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
  }
  .details_category {
    margin: 0;
  }
  .details_select {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 800px;
    width: auto;
  }
}
@media (min-width: 800px) and (max-width: 940px) {
  .profile_editor {
    width: 90%;
  }
}
@media (min-width: 755px) and (max-width: 799px) {
  .profile_editor {
    width: 80%;
  }
}
@media screen and (max-width: 650px) {
  .top_part {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;
    align-content: center;
  }
  .passwords {
    display: flex;
    flex-wrap: wrap;
  }
  .image_uploaders {
    margin-bottom: 10px;
  }
  .email_change{
    width: 100%;
    display: block;
    text-align: left;
    margin-bottom: 30px;
  }
  .email_input_holder>div{
    max-width: 100%;
    width: 100%;
    display: block;
  }
}
