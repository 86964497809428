.home-page-main-container {
    max-width: 1920px;
}

.homepage-text-on-image {
    max-width: 1920px;
    position: relative;
    width: 100%;
}

.homepage-text-on-image-desktop {
    width: 100vw;
    height: auto;
    max-height: 600px;
    object-fit: cover;
    object-position: top;
    opacity: 1;
    margin-bottom: 6px;
}



.homepage-text-on-image-hubs {
    max-width: 1920px;
    position: relative;
    width: 100%;
    margin-top: 200px;
}

.homepage-text-on-image>div {
    position: absolute;
    left: 360px;
    top: 244px;
    width: 27%;
}

.homepage-text-on-image-second {
    position: absolute;
    top: 120px;
    left: 300px;
    width: 27%;
}

.homepage-text-on-image-title {
    font-weight: 900;
    font-size: 64px;
    line-height: 82px;
    color: #000000;
    margin-bottom: 35px;
}

.homepage-text-on-image-titleup {
    font-weight: 900;
    font-size: 80px;
    text-align: center;
    line-height: 82px;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 15px;
}

.homepage-text-on-image-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 56px;
}

.home-page-counters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 56px 18%;
    gap: 10px;
    width: 100%;
    max-width: 1920px;
    left: 0px;
    top: 852px;
    background: #1EBEDC;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin-top: -6px;
}

.home-page-counters-number {
    font-weight: 900;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 8px;
}

.home-page-counters>div {
    display: flex;
    align-items: center;
    padding: 0 3%;
}

.home-page-counter-icon {
    margin-right: 5px;
}

.home-page-counters-title-box {
    max-width: 1920px;
    padding: 160px 18%;
 }

.home-page-counters-title-box-title {
    font-weight: 900;
    font-size: 64px;
    line-height: 82px;
    text-align: center;
    color: #262626;
    margin: 0 150px;

}

.home-page-counters-title-box-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #262626;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-page-counters-title-box-description>div {
    margin-top: 30px;
    padding: 0 40px;
    width: 55%;
}

.home-page-testimonials {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    max-width: 1920px;
}

.home-page-testimonials-inner-box {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    width: 63%;
    position: relative;
}

.home-page-join-startup-section {
    display: flex;
    align-items: center;
    max-width: 1920px;
    margin-top: 50px;
}

.home-page-join-startup-section-one {
    margin-left: 17%;
    z-index: 1;
}

.home-page-join-startup-section-two {
    background: #1EBEDC;
    padding-top: 100px;
    padding-left: 180px;
    padding-right: 8%;
    padding-bottom: 100px;
    margin-left: -150px;
}

.home-page-join-startup-section-two-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 62px;
    color: #262626;
}

.home-page-join-startup-section-two-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #262626;
    margin-top: 40px;
}

.home-page-join-startup-section-two>div {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #80BC00;
    margin-top: 50px;
}

.homepage-text-on-image-title-for-hubs {
    font-weight: 700;
    font-size: 42px;
    line-height: 62px;
    color: #000000;
    margin-bottom: 16px;
}

.homepage-text-on-image-description-for-hubs {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 56px;
}

.homepage-text-on-image-title-try-for-free {
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    color: #FFFFFF;
    width: 52%;
    margin-bottom: 20px;
}

.home-page-try-for-free {
    margin-top: 0;
}

.home-page-learn-more-section {
    display: flex;
    align-items: center;
    max-width: 1920px;
    margin-top: 200px;
}

.home-page-learn-more-section-one {
    margin-left: 19%;
    z-index: 1;
}

.home-page-learn-more-section-two {
    background: #FBF7FF;
    padding-top: 100px;
    padding-left: 200px;
    padding-right: 18%;
    padding-bottom: 100px;
    margin-left: -130px;
}

.home-page-learn-more-section-two-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 62px;
    color: #262626;
}

.home-page-learn-more-section-two-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #262626;
    padding-left: unset;
    margin-top: 16px;
}

.home-page-learn-more-section-two-description>li {
    margin-left: 24px;
}


.home-page-image-slider>div>div>div {
    width: 100%;
}

.home-page-events {
    width: 80%;
    max-width: 1920px;
    margin: 0 auto;
}

.home-page-events-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.home-page-events-cards>.card>.content>.Card-header>.deadline-location>.deadline {
    margin-right: 5px;
    margin-bottom: 0.5rem;
}

.home-page-events-cards>div {
    max-width: 23%;
}

.home-page-events-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
    color: #262626;
    margin-top: 100px;
    margin-bottom: 56px;
}

.home-page-helping-startups-title-with-padding {
    padding: 0px 120px;
}

.home-page-events-cards>div>div>.Card-header {
    margin-bottom: 0;
    height: 310px;
    position: relative;
}

.card-title-homepage-navigate {
    position: absolute;
    bottom: 0;
}

.home-page-best-things {
    width: 80%;
    margin: 0 auto;
    display: flex;
    padding: 32px;
    margin-top: 100px;
    background: #FFFFFF;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: relative;
}

.home-page-best-things-sections {
    padding-left: 55px;
    padding-right: 55px;
    z-index: 2;
}

.home-page-best-things-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    color: #262626;
}

.home-page-best-things-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #262626;
    margin-bottom: 24px;
}

.home-page-best-things-sections-image-holder {
    background: rgba(103, 85, 206, 0.1);
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.home-page-best-things-image {
    max-width: 24px;
    max-height: 24px;
}

.home-page-best-things-sections-middle {
    border-left: 1px solid rgba(103, 85, 206, 0.15);
    /* border-right: 1px solid rgba(103, 85, 206, 0.15); */
}

.home-page-best-things-list {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #80BC00;
    margin-top: 12px;
    z-index: 1;
}

.home-page-best-things-navigate {
    margin-top: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #80BC00;
}

.home-page-rocket-image {
    position: absolute;
    max-height: 866px;
    right: -120px;
    max-width: 60%;
    top: 310px;
    z-index: 0;
}

.home-page-newsletter-container {
    background: #E1DBFF;
    padding: 120px 18%;
}

.home-page-newsletter-box {
    margin: 0 auto;
    max-width: 80%;
    text-align: center;
}

.home-page-newsletter-box-header {
    margin-bottom: 40px;

}

.home-page-newsletter-box-header-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
    color: #262626;
    margin-bottom: 10px;
}

.home-page-newsletter-box-header-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #262626;
}

.home-page-newsletter-box-body-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #262626;
    margin-bottom: 10px;
}

.home-page-newsletter-box-body {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    align-items: end;
    flex-wrap: wrap;
}

.home-page-newsletter-box-body-email-container {
    width: 65%;
}

.home-page-newsletter-box-body>span>input {
    padding: 13px 16px;
    gap: 10px;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #E1DBFF;
    border-radius: 3px;
}

.home-page-newsletter-box-body>span>button {
    height: 49px;
    width: 131px;
}

.home-page-helping-startups-container {
    margin-top: 200px;
    align-items: center;
    padding: 160px 3%;
    gap: 26px;
    max-width: 1920px;
    left: 0px;
    background: #FFFFFF;
}

.home-page-helping-startups-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
    color: #262626;
    margin-bottom: 30px;
}

.home-page-helping-startups-desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #262626;
    padding: 0 10%;
}

.home-page-how-it-works-container {
    display: flex;
    align-items: center;
}

.home-page-counters-title-box-text{
}

.home-page-how-it-works {
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    color: #80BC00;
    width: 30%;
    margin-bottom: unset;
}

.homepage-text-on-image-mobile {
    display: none;
}

.homepage-text-on-image-desktop-first {
    width: 100%;
    position: relative;
}

.homepage-text-on-image-first {
    position: relative;
}

.homepage-text-on-image-first>div {
    position: absolute;
    top: 120px;
    left: 240px;
    width: 27%;
}

.homepage-text-on-image-first>div>.homepage-text-on-image-title {
    font-size: 44px;
    line-height: 64px;
}

.homepage-text-on-image-first>div>.homepage-text-on-image-description {
    font-size: 16px;
    line-height: 25px;
    width: 25rem;
}


.homepage-text-on-image-desktop-two {
    width: 100%;
}

.home-page-how-it-works-container:hover {
    cursor: pointer;
}

.home-page-join-startup-section-one {
    width: 95%;
    aspect-ratio: 16/9;
}

.homepage-embed-video {
    width: 100%;
    height: 100%;
}

.homepage-embed-video>iframe {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.home-page-first-box-button {
    width: fit-content;
    min-width: 150px;
    height: 48.07px;
    background: #1EBEDC;
    border: 1px solid #ffffff;
}

.home-page-testimonials-masonry {
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
}

.testimonials-list-column:nth-of-type(2) {
    padding-left: 24px;
}

.testimonials-list-column>div {
    margin-top: 24px;
}

.homepage-text-on-image-description-for-hubs>div {
    margin-bottom: 1em;
}

.home-page-best-things-sections>div {
    height: 400px;
}

.home-page-popup-container {
    display: flex;
    padding: 70px;
    min-width: 1100px;
    max-width: 75%;
}

.homepage-description-container-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #2B2C34;
    margin-bottom: 13px;
}

.homepage-popup-image-container>img {
    height: 420px;
    margin-right: 65px;
}

.homepage-description-container-input {
    margin: 20px 0;
}

.homepage-description-container-input>span {
    border-radius: 13px;
}

.homepage-description-container-input>span>input {
    border-radius: 13px;
    height: 55px;
}

.homepage-description-container-input>span>button {
    height: 55px;
    width: 135px;
    border-top-right-radius: 13px !important;
    border-bottom-right-radius: 13px !important;
}

.homepage-description-container-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2B2C34;
}

.homepage-description-container-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #6246EA;
    margin-bottom: 30px;
}

.homepage-description-container-oneline {
    margin-bottom: 17px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2B2C34;
}

.subscription-popup-close-icon {
    margin-left: -45px;
    margin-top: 30px;
}

.homepage-description-container-logos>img {
    margin: 5px;
    margin-bottom: 20px;
}

.homepage-counter-center-box{
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

@media(max-width:1700px) {
    .homepage-text-on-image>div {
        width: 35%;
    }

    .homepage-text-on-image-second {
        width: 55%;
        left: 120px
    }

    .home-page-counters {
        padding: 56px 3%;
    }

    .home-page-join-startup-section-one {
        margin-left: 15%;
    }

    .home-page-join-startup-section-two {
        padding-right: 14%;
    }

    .home-page-learn-more-section-two {
        padding-right: 15%;
    }

    .home-page-learn-more-section-one {
        margin-left: 15%;
    }

    .home-page-helping-startups-container {
        padding: 160px 5%;
    }

    .home-page-helping-startups-title {
        padding: 0 70px;
    }
}

@media(max-width:1600px) {
    .home-page-join-startup-section-one {
        margin-left: 12%;
    }

    .home-page-join-startup-section-two {
        padding-right: 10%;
    }

    .home-page-events-cards>div {
        max-width: 24%;
    }

    .home-page-try-for-free>div {
        width: 50%;
        top: 180px;
    }

    .home-page-learn-more-section-two {
        padding-right: 10%;
        margin-top: -100px;
    }

    .home-page-learn-more-section-one {
        margin-left: 10%;
    }

    .home-page-newsletter-box-body {
        flex-direction: row;
    }

    .home-page-newsletter-box-body>span {
        margin-bottom: 25px;
    }

    .home-page-counters-title-box-description>div {
        width: 80%;
    }

    .home-page-helping-startups-title {
        padding: 0 50px;
    }

    .home-page-newsletter-box {
        width: 100%;
    }

}

@media(max-width:1410px) {
    .homepage-text-on-image>div {
        width: 80%;
    }

    .homepage-text-on-image-second {
        width: 80%;
    }

    .home-page-counters-title-box {
        padding: 160px 10%;
    }

    .home-page-join-startup-section-one {
        margin-left: 15%;
    }

    .home-page-join-startup-section-one {
        margin-left: 8%;
        display: flex;
        justify-content: center;
    }

    .home-page-join-startup-section-two {
        max-width: 63%;
    }

    .home-page-learn-more-section {
        flex-wrap: wrap
    }

    .home-page-learn-more-section-one {
        margin: 0 auto;
    }

    .home-page-learn-more-section-one>img {
        max-width: 95%;
    }

    .home-page-join-startup-section-one>div {
        max-width: 95%;
    }

    .home-page-rocket-image {
        right: -90px;
    }

    .homepage-text-on-image-second {
        top: 90px;
    }

    .home-page-try-for-free>div {
        left: 200px;
    }

    .home-page-how-it-works {
        width: 36%;
    }

    .homepage-text-on-image-first>div {
        top: 60px;
    }

    .home-page-helping-startups-container {
        padding: 160px 7%;
    }

}

@media(max-width:1200px) {
    .homepage-text-on-image-title {
        font-size: 42px;
        line-height: 56px;
    }

    .homepage-text-on-image-description {
        margin-bottom: 30px;
    }

    .home-page-counters-title-box {
        padding: 160px 2%;
    }

    .homepage-text-on-image-second {
        width: 90%;
        left: 30px;
    }

    .homepage-text-on-image-description-for-hubs {
        margin-bottom: 20px;
    }

    .homepage-text-on-image-title-for-hubs {
        font-size: 32px;
        line-height: 40px;
    }

    .home-page-events-cards>div {
        max-width: 49%;
    }

    .home-page-try-for-free>div {
        top: 130px;
    }

    .homepage-text-on-image-title-try-for-free {
        width: 80%;
    }

    .home-page-helping-startups-title {
        padding: unset;
    }

    .homepage-text-on-image-first>div {
        top: 120px;
        left: 120px;
    }

    .homepage-text-on-image-first>div>.homepage-text-on-image-title {
        font-size: 22px;
        line-height: 40px;
    }

    .homepage-text-on-image-first>div>.homepage-text-on-image-description {
        font-size: 15px;
        line-height: 25px;
        width: 13rem;
    }

    .home-page-best-things {
        flex-wrap: wrap;
    }

    .home-page-best-things-sections-middle {
        border-left: unset;
        border-right: unset;
        border-top: 1px solid rgba(103, 85, 206, 0.15);
        /* border-bottom: 1px solid rgba(103, 85, 206, 0.15); */
        margin-top: 30px;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .home-page-join-startup-section-two {
        margin-left: 0;
        margin-top: -70px;
        padding: 12%;
    }

    .home-page-join-startup-section {
        flex-wrap: wrap;
        justify-content: center;
    }

    .home-page-popup-container {
        min-width: 900px;
    }
    .home-page-counters {
        flex-wrap: wrap;
    }
    .homepage-counter-center-box{
        border-right: unset;
    }
}

@media(max-width:970px) {
    .homepage-text-on-image>div {
        top: 80px;
        left: 80px;
    }

    .homepage-text-on-image-title {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 10px;
    }

    .home-page-rocket-image {
        right: -75px;
    }

    .home-page-try-for-free>div {
        top: 100px;
    }

    .home-page-how-it-works {
        width: 40%;
    }

    .home-page-counters-title-box-description>div {
        width: 42%;
    }

    .home-page-newsletter-box-body {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .homepage-text-on-image-first>div {
        top: 50px;
    }

    .home-page-helping-startups-container {
        padding: 80px 14%;
    }

    .home-page-popup-container {
        flex-wrap: wrap;
        min-width: 700px;
    }

    .homepage-popup-image-container {
        text-align: center;
        display: contents;
    }

    .homepage-description-container-input {
        margin: 20px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .homepage-description-container-input>input {
        height: 55px;
        border-radius: 13px;
        margin-bottom: 15px;
    }

    .homepage-description-container-input>button {
        height: 55px;
        width: 150px;
        border-radius: 13px;
        margin-bottom: 15px;
    }
}

@media(max-width:800px) {
    .homepage-text-on-image-description-for-hubs>p {
        margin-bottom: 5px;
    }

    .homepage-text-on-image-second {
        width: 95%;
    }
    .homepage-counter-center-box{
        border-left: unset;
    }
}

@media(max-width:750px) {
    .homepage-text-on-image>div {
        top: 80px;
        left: 80px;
        width: 85%;
    }

    .homepage-text-on-image-description {
        font-size: 16px;
        line-height: 20px;
    }

    .homepage-text-on-image-title {
        font-size: 26px;
        line-height: 30px;
    }

    .home-page-join-startup-section-two {
        max-width: 100%;
    }

    .homepage-text-on-image-title-for-hubs {
        font-size: 20px;
        line-height: 26px;
    }

    .homepage-text-on-image-description-for-hubs>p {
        font-size: 14px;
        line-height: 18px;
    }

    .home-page-events-cards>div {
        max-width: 85%;
    }

    .homepage-text-on-image-title-try-for-free {
        font-size: 26px;
        line-height: 30px;
    }

    .home-page-newsletter-box {
        max-width: 100%;
    }

    .home-page-newsletter-box-body>span>input {
        width: 300px;
    }

    .home-page-counters-title-box-title {
        font-size: 42px;
        line-height: 54px;
        margin: 0 70px;
    }

    .home-page-best-things {
        width: 80%;
    }

    .home-page-rocket-image {
        right: -45px;
    }

    .home-page-how-it-works {
        width: 48%;
    }

    .home-page-counters-title-box-description>div {
        width: 48%;
        flex-wrap: wrap;
    }

    .homepage-text-on-image-first>div {
        top: 10px;
        left: 50px;
        width: 45%;
    }

    .homepage-text-on-image-hubs {
        margin-top: 0;
    }

    .home-page-popup-container {
        flex-wrap: wrap;
        min-width: 500px;
        padding: 70px 35px;
    }
}

@media(max-width:650px) {
    .homepage-text-on-image-desktop {
        display: none;
    }

    .homepage-text-on-image-mobile {
        display: block;
        width: 100%;
    }

    .homepage-text-on-image-second {
        top: 250px
    }

    .home-page-newsletter-box-body-email-container {
        width: 95%;
    }

}

@media(max-width:600px) {
    .home-page-counters-title-box-description {
        flex-wrap: wrap;
    }

    .home-page-counters-title-box-description>div {
        width: 80%;
    }

    .home-page-how-it-works-container {
        border-left: unset;
    }

    .homepage-text-on-image>div {
        top: 40px;
        left: 40px;
    }

    .homepage-text-on-image-second {
        top: 150px;
        width: 90%;
    }

    .homepage-text-on-image-first>div>.homepage-text-on-image-title {
        font-size: 16px;
        line-height: 22px;
    }

    .home-page-join-startup-section-two {
        padding: 15% 12%;
    }

    .home-page-popup-container {
        flex-wrap: wrap;
        min-width: 400px;
        padding: 70px 10px;
    }

    .homepage-popup-image-container>img {
        height: unset;
        width: 100%;
    }
}

@media(max-width:500px) {

    .home-page-popup-container {
        flex-wrap: wrap;
        min-width: 300px;
        padding: 70px 10px;
    }
}

@media(max-width:450px) {
    .homepage-text-on-image-description {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
    }

    .homepage-text-on-image-title {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .home-page-rocket-image {
        right: -5px;
    }

    .home-page-best-things-sections {
        padding-left: 25px;
        padding-right: 25px;
    }

    .home-page-events-cards>div {
        max-width: 95%;
    }

    .homepage-text-on-image-title-try-for-free {
        margin-bottom: 10px;
    }

    .homepage-text-on-image-title-try-for-free {
        font-size: 20px;
        line-height: 24px;
    }

    .home-page-newsletter-box-body>span>input {
        width: 250px;
    }

    .homepage-text-on-image-second {
        top: 50px
    }

    .homepage-text-on-image-first>div {
        width: 69%;
    }

    .homepage-text-on-image-first>div>.homepage-text-on-image-description {
        width: 90% !important;
        line-height: 18px;
    }

    .homepage-text-on-image-first>div>.homepage-text-on-image-title {
        margin-bottom: 5px;
        line-height: 19px;
    }

    .homepage-text-on-image-first>div {
        left: 15px;
    }
}