.registration-container {
}
.registration-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  margin-top: 80px;
  flex-wrap: wrap;
  padding: 10px;
}
.step-container {
  width: 300px;
}
.steps-content{
  max-width: 1300px;
  margin: auto
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
}
.registration-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #262626;
}
.navigation-container {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;
  margin-bottom: 32px;
}
.buttons-container button {
  width: 144px;
  height: 40px;
  font-weight: 900;
}
.skip-registration-step {
  color: var(--primary-blue);
  margin: auto;
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 50px;
}

.skip-registration-step:hover {
  cursor: pointer;
}
.define-container {
  gap: 25px;
  display: flex;
  flex-direction: column;
}
.main-category {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #262626;
  display: flex;
  width: fit-content;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 800px;
  max-width: 550px;
}
.details-select {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 450px;
}
.check-select {
  margin-bottom: 20px;
  margin-left: 0 !important;
}
.selected-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-items-container {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.detail-item {
  display: flex;
  flex-direction: row;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  border: 1px solid #e1dbff;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 10px 15px;
}
.title-category {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  color: #262626;
}
.title-details {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  color: #6755ce;
  margin-top: 25px;
  margin-bottom: 25px;
}
.details-category {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.main-category-item {
  width: 100%;
  cursor: pointer;
}
.main-category-item-selected {
  color: #6755ce;
  font-weight: 800;
  width: 100%;
}

.check-select span {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
.skill-select-holder {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.selected-item {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-left: 10px;
}

.main-category-holder {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.main-category-holder-selected {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  color: #6755ce;
  font-weight: 800;
  width: 47%;
}

.main-category-holder > .main-category-item {
  flex: 1 1 47%;
}

.skip-registration-step:hover {
  cursor: pointer;
}

.register-column-firstpage {
  display: flex;
  width: 54%;
  max-width: 595px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.register-column-field {
  width: 275px;
  padding-bottom: -10px;
  margin-bottom: 28px;
  height: 90px;
}

.register-column-field div span {
  width: 100% !important;
}
.register-column-field input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.education-register {
  width: 595px;
}

.oneliner-register {
  width: 595px;
}

.bio-register {
  width: 595px;
}

.step-title-register {
  font-size: 30px;
  text-align: start;
  margin: 1em 0 2em;
  font-weight: bold;
}

.step-userinfo-form {
  margin-top: 50px;
}

.register-column-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px;
}
.register-secondcolumn-firstpage {
  display: flex;
  width: 35%;
  margin-left: 100px;
  justify-content: space-around;
}

.register-secondcolumn-firstpage .ImageUploader {
  width: 100%;
  margin-top: 10px;
}
.uploader-with-text{
  height: fit-content;
  text-align: center;
}

.social-dropdown {
  width: 100%;
  height: 32px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #262626;
  padding-left: 10px;
}

.social-link {
  width: 272px;
  display: flex;
  justify-content: space-between;
}

.item-title-register {
  width: 50%;
  display: inline-block;
  font-size: 14px;
  font-weight: 800;
}

.city-image {
  width: auto;
  margin-right: 8px;
}

.more-social-register {
  margin-top: -15px;
  width: 100%;
}
.more-social-register:hover {
  cursor: pointer;
}
::placeholder {
  font-style: italic;
}
.register-input-icon {
  height: 15px;
}
.ul-register-bio {
  margin-top: -20px;
  margin-bottom: 25px;
  padding-left: 20px;
}
.social-dropdown-register {
  width: 272px;
}
.register-social-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hide-birthday-register {
  margin-top: 10px;
}

.social-link-trash {
  cursor: pointer;
  font-size: 24px;
  color: #6755ce;
  height: 100%;
  margin-left: 8px;
}

.remaining-days {
  font-weight: 900;
}

@media (max-width: 1350px) {
  .register-column-firstpage {
    width: 60%;
    margin: auto;
  }
}
@Media (min-width:1080px) and (max-width: 1150px){
  .register-column-container {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
}
}
@media (max-width: 1000px) {
  .register-column-container {
    flex-direction: column-reverse;
  }
  .register-secondcolumn-firstpage {
    width: 50%;
    margin: auto;
  }
  .register-column-firstpage {
    width: unset;
  }
}

@media (max-width: 700px) {
  .register-secondcolumn-firstpage {
    width: 100%;
    margin-left: 0;
  }
  .register-column-firstpage {
    width: 94%;
  }
  .social-dropdown-register {
    width: 99%;
  }
}

.not-activated-button-container {
  display: flex;
  justify-content: center;
}

.trial-ended-button-container {
  display: flex;
}

.not-activated-button-container button {
  margin: 0 15px;
  width: 144px;
  height: 40px;
  font-weight: 900;

}

.not-activated-continue {
  background-color: white;
  color: var(--primary-blue);
  border: 2px solid var(--primary-blue);
  margin-left: 16px;
}
@media (max-width: 600px) {
  .register-column-field {
    width: 100%;
  }
  .register-column-field div {
    max-width: 100%;
  }
  .social-link {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .step-userinfo-image {
    margin-bottom: 15px;
    border: none;
  }
}

@media (max-width: 650px) {
  .Registration-steps-line {
    width: 60%;
  }

  .Registration-steps-line
    .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    display: flex;
    justify-content: space-between;
  }

  .Registration-steps-line
    .ant-steps
    .ant-steps-horizontal
    .ant-steps-label-horizontal {
    display: inline-block;
  }

  .Registration-steps-line
    .ant-steps-horizontal.ant-steps-label-horizontal
    .ant-steps-item-icon {
    margin: 0;
  }

  .Registration-steps-line .ant-steps-item-tail:after {
    display: none;
  }
}


@media screen and (max-width: 1200px) {
  .select-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .details-select {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 450px;
    width: 780px;
  }
}
@media (min-width: 320px) and (max-width: 700px) {
  .registration-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1300px;
    margin: auto;
    margin-top: 80px;
    flex-wrap: wrap;
    
  }
  .registration-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #262626;
    text-align: center;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: flex !important;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: none;
  }

  .selected-items {
    justify-content: center;
    flex-wrap: wrap;
  }

  .detail-item {
    margin-left: 0;
  }

  .selected-item {
    flex-direction: column-reverse;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
  }
  .selected-items > div:nth-child(2) {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .details-select {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: fit-content;
    width: auto;
  }
  .details-category {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .main-category {
    flex-direction: column;
    max-height: fit-content;
    max-width: fit-content;
  }
  .main-category-holder-selected {
    width: 100%;
  }
  .title-category {
    width: 100%;
    text-align: center;
  }
  .main-category-holder > .main-category-item {
    flex: 0 1 100%;
  }
  .select-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-content: center;
  }
  
}
